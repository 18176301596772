
import {

    WarehouseModel,
} from '@common/modelDefinition';

export interface RoomAndReservation {
    roomInfoId: number;
    reservationId: number;
}

export interface CreateNewConsumption {
    roomInfoId: number;
    reservationId: number | null;
    warehouse: WarehouseModel;
}


export enum RoomReservationStatusText {
    'arrival' = 'Arrival',
    'checkedIn' = 'Checked in',
    'dueOut' = 'Due Out',
    'checkedOut' = 'Checked out',
    'stay' = 'Stay',
}

export enum RoomReservationStatusColors {
    'arrival' = '#5895f2',
    'checkedIn' = '#009934',
    'dueOut' = '#fc8518',
    'checkedOut' = '#69778c',
    'stay' = '#abcaf8',
}
export enum RoomReservationStatusTextColors {
    'arrival' = '#dbe8fc',
    'checkedIn' = '#009934',
    'dueOut' = '#dbe8fc',
    'checkedOut' = '#dbe8fc',
    'stay' = '#00112b',
}
export enum TaskGroupEnum {
    'maintainanceTask' = 'Maintainance',
    'reservationTask' = 'Reception',
    'thredTask' = 'Conversation',
    'cleaningTask' = 'Cleaning',
}
export interface MaidInterface {
    uuid: string;
    workingHours: number;
}

export interface Assigments {
    roomInfoIds: number[];
    selectedMaids: MaidInterface[];
    taskHeaderId: number | null;
    roomReservationStatuses: any;
}

export interface ChangeTasksRoom {
    roomInfoId: number;
    userAssigned: string;
    taskGroup: string;
    id?: number;
}

export interface ReservationStatusObj {
    roomInfoId: number;
    reservationId: number;
}

export enum TaskColorEnum {
    'color-none',
    'color-warning',
    'color-info',
    'color-primary',
    'color-success',
    'color-secondary',
    'color-error',
}

export enum TaskBackgroundColorEnum {
    '#ffffff',
    '#fc8518 ',
    '#abcaf8',
    '#1068ec',
    '#17e15c',
    '#8f9db2',
    '#ec1010',
}

export enum TaskStatusEnum {
    noActionTaken,
    taskRequested, //atomatski sa SRC-a
    stagedForTask, //znači da se netko obvezao na čišćenje - čistačica.... ili je soba deklarirana za čišćenje sa backenda
    taskStarted,
    taskFinished,
    taskPostponed, //samo ako je bila stageana
    taskSkipped, //sa frontenda u ovisnosti o prethodnim čišćenjima - uloga čistačice.... ili ako je soba deklariarna da se ne čisti sa backenda
    //added 12.8.2020
    /*ubaciti dodane informaicje o rezervaciji "reservationStatus"- ako se nalozi generiraju automatski,
	za sve ove rezervacije će biti cleaningStatus=stagedForCleaning*/

    //stayover
    //due out (cheking in)
    //arrival (cheking out)
}
export enum TaskStatusEnumText {
    'No action taken',
    'Requested', //atomatski sa SRC-a
    'Staged for task', //znači da se netko obvezao na čišćenje - čistačica.... ili je soba deklarirana za čišćenje sa backenda
    'Started',
    'Finished',
    'Postponed', //samo ako je bila stageana
    'Skipped', //sa frontenda u ovisnosti o prethodnim čišćenjima - uloga čistačice.... ili ako je soba deklariarna da se ne čisti sa backenda
    //added 12.8.2020
    /*ubaciti dodane informaicje o rezervaciji "reservationStatus"- ako se nalozi generiraju automatski,
	za sve ove rezervacije će biti cleaningStatus=stagedForCleaning*/

    //stayover
    //due out (cheking in)
    //arrival (cheking out)
}

export enum TaskStatusClassNames {
    'color',
    'color-requested', //atomatski sa SRC-a
    'color-staged', //znači da se netko obvezao na čišćenje - čistačica.... ili je soba deklarirana za čišćenje sa backenda
    'color-started',
    'color-finished',
    'color-postponed', //samo ako je bila stageana
    'color-skipped', //sa frontenda u
}

export enum RoomStatusButtonsClassNames {
    'arrival' = 'button-arrival',
    'checkedIn' = 'button-checked-in',
    'dueOut' = 'button-due-out',
    'checkedOut' = 'button-checked-out',
    'stay' = 'button-stay',
}
export enum RoomStatusInfoClassNames {
    'arrival' = 'text-arrival',
    'checkedIn' = 'text-checked-in',
    'dueOut' = 'text-due-out',
    'checkedOut' = 'text-checked-out',
    'stay' = 'text-stay',
}

export enum CleaningTaskStatusEnumText {
    'No action taken',
    'Requested', //atomatski sa SRC-a
    'Staged', //znači da se netko obvezao na čišćenje - čistačica.... ili je soba deklarirana za čišćenje sa backenda
    'Started',
    'Finished',
    'Postponed', //samo ako je bila stageana
    'Skipped', //sa frontenda u ovisnosti o prethodnim čišćenjima - uloga čistačice.... ili ako je soba deklariarna da se ne čisti sa backenda
    //added 12.8.2020
    /*ubaciti dodane informaicje o rezervaciji "reservationStatus"- ako se nalozi generiraju automatski,
	za sve ove rezervacije će biti cleaningStatus=stagedForCleaning*/

    //stayover
    //due out (cheking in)
    //arrival (cheking out)
}