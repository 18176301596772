import React, { useState, useRef, useEffect } from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
// import baobabTree from '../../data/state';
import '../roomInfo/style.css';
import Tooltip from '../../components/tooltip/tooltip';
import SearchField from '../search/searchField';
// import Select from 'react-select';
import { CleaningStatusEnum } from '@pages/household/interfaces';

import { ReactComponent as ChevronDown } from '../../assets/custom/icons/chevron_down.svg';
import { ReactComponent as Close } from '../../assets/custom/icons/close.svg';
import { ReactComponent as Filter } from '../../assets/custom/icons/filter.svg';
import { AttributesModel, UserInfo } from '@common/modelDefinition';
import translate from '@data/translations';

interface ValueLabel {
    label: string;
    value: any;
}

interface CustomSelectProps {
    isMulti?: boolean;
    options: ValueLabel[];
    value: ValueLabel[];
    placeholder?: string;
    onChange: Function;
    width?: string;
    styles: any;
    className?: string;
    isDisabled?: boolean;
}

export function CustomSelect(props: CustomSelectProps) {
    const { isMulti, options, value, placeholder = 'Select option', onChange, width, isDisabled } = props;
    const [showOptions, setShowOptions] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [inputWidth, setInputWidth] = useState('2ch');
    const wrapperRef = useRef(null);
    const inputRef = React.useRef(null as any);

    let filteredOptions: ValueLabel[] = [];

    if (options) {
        options.forEach((element) => {
            if (
                value &&
                !value?.find(({ value }) => value === element.value) &&
                element?.label?.toLowerCase().includes(searchString)
            ) {
                filteredOptions.push(element);
            }
        });
    }

    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (!showOptions) {
                        setShowOptions(false);
                    }
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    function addOrRemoveItem(item: ValueLabel) {
        let valuesCopy = value ? [...value] : [];
        const index = valuesCopy.indexOf(item);
        if (index > -1) {
            // only splice array when item is found
            valuesCopy.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            valuesCopy.push(item);
        }
        onChange(valuesCopy.length > 0 ? valuesCopy : null);
    }

    function selectThisOption(itm: ValueLabel) {
        addOrRemoveItem(itm);
        setShowOptions(false);
        setSearchString('');
        inputRef.current.focus();
    }

    function removeThisItem(item: ValueLabel) {
        addOrRemoveItem(item);
    }

    function searchForOption(e: any) {
        if (!showOptions) {
            setShowOptions(true);
        }
        setSearchString(e.target.value);
        setInputWidth(e.target.value.length + 1 + 'ch');
    }

    //  console.log('inputWidth', value.length> 0, inputWidth);

    return (
        <div
            ref={wrapperRef}
            className={'custom-select-wrapper ' + (isDisabled ? 'disabled' : '')}
            style={width ? { width: width } : {}}
        >
            <div
                className="custom-select-flex"
                onClick={(e) => {
                    e.stopPropagation();
                    inputRef.current.focus();
                    setShowOptions(!showOptions);
                }}
            >
                <div className="custom-select-selected-items-wrapper">
                    {value && value.length > 0
                        ? value.map((val, index) => {
                              return (
                                  <span key={index} className="selected-item">
                                      <span>{val.label}</span>
                                      {val && isMulti ? (
                                          <i
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  removeThisItem(val);
                                              }}
                                              className="fa fa-close"
                                          ></i>
                                      ) : null}
                                  </span>
                              );
                          })
                        : //  <div  onClick={(e) => {e.stopPropagation();  setShowOptions(!showOptions) }}>{placeholder}</div>
                          null}

                    <input
                        disabled={isDisabled ? true : false}
                        id="search-string"
                        ref={inputRef}
                        placeholder={value?.length > 0 ? '' : placeholder}
                        style={{ width: value?.length > 0 ? inputWidth : placeholder.length + 'ch' }}
                        value={searchString}
                        onChange={(e) => {
                            searchForOption(e);
                        }}
                        className='input input-modal'
                        type="text"
                    ></input>
                </div>

                <div className="display-flex">
                    {value && value.length > 0 ? (
                        <Close
                            style={{ height: '26px', width: '26px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onChange(null);
                            }}
                        />
                    ) : (
                        <ChevronDown />
                    )}
                </div>
            </div>

            {!isDisabled && showOptions && filteredOptions?.length > 0 ? (
                <div className="options-wrapper">
                    {filteredOptions.map((opt, ind) => {
                        return (
                            <div
                                className="option"
                                key={ind}
                                onClick={() => {
                                    selectThisOption(opt);
                                }}
                            >
                                {opt.label}
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: state.isDisabled ? '#f3f6fb' : '#fff',
        borderColor: '#D8E0EC',
        minHeight: '32px',
        // height: '32px',
        minWidth: '178px',
        boxShadow: state.isFocused ? null : null,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
    }),

    valueContainer: (provided: any, state: any) => ({
        ...provided,
        // height: '32px',
        padding: '0 6px',
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        // height: '32px',
    }),
    menu: (base: any) => ({ ...base, zIndex: 9999 }),
};

interface ToolbarProps {
    history: any;
    // setHideRoomByIdFilter: Function;
    // setAvailableFromAndToTimestamps: Function;
    // setNumberOfBeds: Function;
    // setNumberOfSpareBeds: Function;
    roomTags: string[];
    tagsFilter: string[];
    attributes: AttributesModel[];
    attributesFilter: number[];
    cleaningStatusesFilter: number[];
    setSearchTerm: Function;
    toggleCleaningStatusesFilter: Function;
    toggleTagFilter: Function;
    handleAttributesSelect: Function;
    attributesFilteringType: boolean;
    setAttributesFilteringType: Function;
    loggedUser: UserInfo;
}

const Toolbar = (props: ToolbarProps) => {
    const {
        // setHideRoomByIdFilter,
        // setAvailableFromAndToTimestamps,
        // setNumberOfBeds,
        // setNumberOfSpareBeds,
        roomTags,
        tagsFilter,
        attributes,
        attributesFilter,
        cleaningStatusesFilter,
        setSearchTerm,
        history,
        toggleCleaningStatusesFilter,
        toggleTagFilter,
        handleAttributesSelect,
        attributesFilteringType,
        setAttributesFilteringType,
        loggedUser,
    } = props;

    const [toolbarFiltersShown, setToolbarFiltersShown] = React.useState(false as boolean);

    const tagsOptions: ValueLabel[] = roomTags.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    tagsOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

    const tagsValues = tagsFilter.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    // const customStyles = {
    //     control: (base, state) => ({
    //         ...base,
    //         // height: '34px',
    //         // 'min-height': '24px',
    //         zIndex: 1000,
    //     }),
    //     menu: (base) => ({ ...base, zIndex: 9999 }),
    // };

    const attributesOptions = attributes.map((attr: AttributesModel) => {
        return {
            value: attr.id,
            label: translate(attr.name),
        };
    });

    attributesOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    const attributesValues: ValueLabel[] = [];
    attributesFilter.forEach((attrId) => {
        const attr = attributes.find((at: AttributesModel) => at.id === attrId);
        if (attr) {
            const res = {
                value: attr.id,
                label: translate(attr.name),
            };
            attributesValues.push(res);
        }
    });

    let clStatusesOptions: ValueLabel[] = [];
    Object.keys(CleaningStatusEnum).forEach((cleaningStatusString: string) => {
        if (isNaN(Number(cleaningStatusString))) {
            const cleaningStatusValue = cleaningStatusString
                ? CleaningStatusEnum[cleaningStatusString as keyof typeof CleaningStatusEnum]
                : null;
            if (cleaningStatusValue) {
                const obj = {
                    value: cleaningStatusValue,
                    label: translate(cleaningStatusString),
                };
                clStatusesOptions.push(obj);
            }
        }
    });

    clStatusesOptions = clStatusesOptions.filter(Boolean);
    const clStatusesValues: ValueLabel[] = [];
    cleaningStatusesFilter.forEach((clStatuses: number) => {
        const cl = Object.keys(CleaningStatusEnum).find(
            (cleaningStatusString) =>
                clStatuses === CleaningStatusEnum[cleaningStatusString as keyof typeof CleaningStatusEnum]
        );
        const cleaningStatusValue = cl ? CleaningStatusEnum[cl as keyof typeof CleaningStatusEnum] : null;
        if (cleaningStatusValue) {
            const obj = {
                value: cleaningStatusValue,
                label: translate(cl),
            };
            clStatusesValues.push(obj);
        }
    });

    if (window.innerWidth < 768) {
        return (
            <div className="dashboard-filters-wrapper">
                {/* <div className="dashboard-filters"> */}

                <div className="flex-center-center">
                    <SearchField onChangeHandler={setSearchTerm} className="form-control mt-1 mb-2" history={history} />
                </div>

                <div>
                    <div
                        onClick={() => {
                            setToolbarFiltersShown(!toolbarFiltersShown);
                        }}
                        className="button-white-w-icon toolbar-filters-icon-mobile"
                    >
                        <Filter></Filter>
                    </div>

                    {toolbarFiltersShown ? (
                        <div className="mobile-toolbar-filters">
                            <div className="toolbar-filters-mobile-header">
                                <span>Filters</span>
                                <span
                                    onClick={() => {
                                        setToolbarFiltersShown(false);
                                    }}
                                >
                                    {' '}
                                    <Close />
                                </span>
                            </div>
                            <div className="flex-center-center mobile-toolbar-select" style={{ marginTop: '24px' }}>
                                <div></div>
                                <div>
                                    <CustomSelect
                                        isMulti
                                        styles={customStyles}
                                        className=""
                                        placeholder={translate('By status')}
                                        options={clStatusesOptions}
                                        onChange={(values: ValueLabel[] | null) => {
                                            if (values === null) {
                                                toggleCleaningStatusesFilter(null);
                                            } else {
                                                toggleCleaningStatusesFilter(values[values.length - 1].value);
                                            }
                                        }}
                                        value={clStatusesValues}
                                    ></CustomSelect>
                                </div>
                            </div>

                            <div className="flex-center-center mobile-toolbar-select">
                                <div>
                                    {
                                        <CustomSelect
                                            isMulti
                                            styles={customStyles}
                                            className=""
                                            placeholder={translate('By tag')}
                                            options={tagsOptions}
                                            onChange={(values: ValueLabel[] | null) => {
                                                if (values === null) {
                                                    toggleTagFilter(null);
                                                } else {
                                                    toggleTagFilter(values[values.length - 1].value);
                                                }
                                            }}
                                            value={tagsValues}
                                        ></CustomSelect>
                                    }
                                </div>

                                <div>
                                    <span className="pl-2 text-center">
                                        {roomTags.length > 0 ? (
                                            <Tooltip
                                                message={translate(
                                                    'Tags: Filtering by tag remove filters by previously selected tag'
                                                )}
                                            >
                                                <i
                                                    className="fa fa-info-circle text-primary  pr-1"
                                                    style={{ fontSize: 'initial' }}
                                                ></i>
                                            </Tooltip>
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-center-center mobile-toolbar-select">
                                <div>
                                    <div className="mulitple-select-wrapper">
                                        <CustomSelect
                                            isMulti
                                            styles={customStyles}
                                            className=""
                                            placeholder={translate('By attributes')}
                                            options={attributesOptions}
                                            onChange={(values: ValueLabel[] | null) => {
                                                if (values === null) {
                                                    handleAttributesSelect(null);
                                                } else {
                                                    handleAttributesSelect(values);
                                                }
                                            }}
                                            value={attributesValues}
                                        ></CustomSelect>
                                    </div>
                                </div>
                                <div>
                                    {attributes.length > 0 ? (
                                        <Tooltip
                                            message={translate(
                                                'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes'
                                            )}
                                        >
                                            <i
                                                className="fa fa-info-circle text-primary ml-1  pr-1"
                                                /* title="Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes" */
                                            ></i>
                                        </Tooltip>
                                    ) : null}
                                    {
                                        <input
                                            checked={attributesFilteringType}
                                            onChange={() => {
                                                setAttributesFilteringType(!attributesFilteringType);
                                            }}
                                            className="checkbox-primary"
                                            type="checkbox"
                                        ></input>
                                    }
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* </div> */}
            </div>
        );
    }

    return (
        <div className="dashboard-filters-wrapper">
            <h1>
                {loggedUser?.role === 'hwAdmin' || props.history?.location?.search?.includes('rooms')
                    ? translate('Room controllers')
                    : translate('Reservation table')}
            </h1>
            <div className="dashboard-filters">
                <div className="flex-center-center mr-4p">
                    {loggedUser?.role === 'hwAdmin' || props.history?.location?.search?.includes('rooms') ? null : (
                        <CustomSelect
                            isMulti
                            styles={customStyles}
                            className=""
                            placeholder={translate('By status')}
                            options={clStatusesOptions}
                            onChange={(values: ValueLabel[] | null) => {
                                if (values === null) {
                                    toggleCleaningStatusesFilter(null);
                                } else {
                                    toggleCleaningStatusesFilter(values[values.length - 1].value);
                                }
                            }}
                            value={clStatusesValues}
                        ></CustomSelect>
                    )}
                </div>

                <div className="flex-center-center mr-4p">
                    <span className="pr-4p text-center">
                        {roomTags.length > 0 ? (
                            <Tooltip
                                message={translate('Tags: Filtering by tag remove filters by previously selected tag')}
                            >
                                <i className="fa fa-info-circle color-primary"></i>
                            </Tooltip>
                        ) : null}
                    </span>
                    {
                        <CustomSelect
                            isMulti
                            styles={customStyles}
                            className=""
                            placeholder={translate('By tag')}
                            options={tagsOptions}
                            onChange={(values: ValueLabel[] | null) => {
                                if (values === null) {
                                    toggleTagFilter(null);
                                } else {
                                    toggleTagFilter(values[values.length - 1].value);
                                }
                            }}
                            value={tagsValues}
                        ></CustomSelect>
                    }
                </div>

                <div className="flex-center-center mr-4p">
                    {attributes.length > 0 ? (
                        <Tooltip
                            message={translate(
                                'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes'
                            )}
                        >
                            <i
                                className="fa fa-info-circle color-primary pr-4p"
                                /* title="Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes" */
                            ></i>
                        </Tooltip>
                    ) : null}
                    {
                        <input
                            checked={attributesFilteringType}
                            onChange={() => {
                                setAttributesFilteringType(!attributesFilteringType);
                            }}
                            className="checkbox-primary pointer"
                            type="checkbox"
                        ></input>
                    }

                    <div className="mulitple-select-wrapper" style={{ marginLeft: 0 }}>
                        <CustomSelect
                            isMulti
                            styles={customStyles}
                            className=""
                            placeholder={translate('By attributes')}
                            options={attributesOptions}
                            onChange={(values: ValueLabel[] | null) => {
                                if (values === null) {
                                    handleAttributesSelect(null);
                                } else {
                                    handleAttributesSelect(values);
                                }
                            }}
                            value={attributesValues}
                        ></CustomSelect>
                    </div>
                </div>

                <div className="flex-center-center">
                    {loggedUser?.role === 'hwAdmin' || props.history?.location?.search?.includes('rooms') ? null : (
                        <SearchField onChangeHandler={setSearchTerm} className="form-control" history={history} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        reservations: ['model', 'Reservation'],
        attributes: ['model', 'RoomAttribute'],
        pageTitle: ['pageTitle'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    Toolbar
);
