// import pubsub from "./data/pubsub"
import Keycloak from 'keycloak-js';
import baobabTree, { saveCachedBaobabData } from './data/state';
import rest from "./data/restWrapper";
var keycloak = new Keycloak('/keycloakConfig');

async function setBaobabToken(authenticated) {
	const { realm, realmAccess, resourceAccess, sessionId, token, tokenParsed, refreshToken } = keycloak;

	let role = undefined;
	const instanceNameObj = await rest("/getInstanceName")

	let client = instanceNameObj?.instanceName ? instanceNameObj?.instanceName : "development"

	if (tokenParsed.resource_access[client] && tokenParsed.resource_access[client]?.roles[0]) {
		role = tokenParsed.resource_access[client].roles[0]
	}

	let authToken = await rest("/checkAndAuthenticateKeycloakUser", "POST",{keycloakTokenParsed:{...tokenParsed, role:role}},)
	baobabTree.root.select('keycloak').set({
		realm,
		realmAccess,
		resourceAccess,
		sessionId,
		token,
		tokenParsed,
		refreshToken,
		authenticated,
	});
	baobabTree.root.select('authTokenInfo').set({
		...authToken
	});
	saveCachedBaobabData(baobabTree)

}

async function handleTokenRefresh() {
	const refreshed = await keycloak.updateToken(30);
	if (refreshed) {
		setBaobabToken(refreshed);
	}
}

export async function initKeycloak(params) {
	try {
		// keycloak = new Keycloak('/keycloakConfig');
		// console.log('krecem init', keycloak);
		// let konj = await fetch("/keycloakConfig")
		// console.log('konj', konj);
		const response = await fetch('/keycloakConfig');
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

		if(( result && result.data && (Object.keys(result.data)).length === 0)){

		try {
			if(keycloak){
				let kcBaobab = baobabTree.root.select('keycloak').get()
				if(kcBaobab){
					baobabTree.root.select('keycloak').set(null)
					baobabTree.root.select('authTokenInfo').set(null)	
							}
				// saveCachedBaobabData(baobabTree)
				keycloak.logout()
			}
			// keycloak.logout()
			// setBaobabToken(false)
		} catch (error) {
			console.log('sss');
		}
		}
		
		
		if(keycloak.clientId || result.resource){
	keycloak.onTokenExpired = () => handleTokenRefresh();
			const isAuthenticated = await keycloak.init({
				//onLoad: 'login-required',
				checkLoginIframe: true,
				...params
			});
		if (isAuthenticated) {
			setBaobabToken(isAuthenticated);
		}
		return keycloak;
		}
	} catch (error) {
		console.log('', error);
		return
		
	}
}

export function logout() {
	try{
		if(keycloak.authenticated){
		keycloak.logout();
		}
	} catch(err){}
}

export function attachAuthorizationHeader(headers = {}) {	
	if(keycloak.token){
		headers['Authorization'] = `Bearer ${keycloak.token}`;
	}
}

export function checkClientRole(roleName) {
	return keycloak.hasResourceRole(roleName);

}

export function checkRealmRole(roleName) {
	return keycloak.hasRealmRole(roleName);
}