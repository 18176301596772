import React from 'react';
import { setModalContent, closeModal } from '@components/modal';
import { ReservationStatus } from '@common/modelDefinition';
import { translate } from '@data/translations';
import { Reservation, ReservationController } from '../reservations2/resController';
import { condoStatusCheck } from '../reservations2/utils';
import { reservationEnums } from '@data/reservationStatusEnums';
import { toast } from 'react-toastify';

interface ConfirmModalProps {
    type: ReservationStatusActions;
    confirmActionHandler: (action: ReservationStatusActions) => void;
    continueToCheckout?: (reservation: Reservation, type: ReservationStatusActions) => void;
    reservation: Reservation;
    text?: any;
}

enum ReservationStatusActions {
    confirm = 'confirm',
    checkin = 'checkin',
    cancel = 'cancel',
    checkout = 'checkout',
    earlyBird = 'earlyBird',
    invoice = 'invoice',
}

interface StatusButtonsProps {
    reservation: Reservation;
    statusButtonActionHandler: (action: string, applyCurrentTime?: boolean) => void;
    history: any;
    isPending: boolean;
}

function ConfirmModal(props: ConfirmModalProps) {
    const { reservation } = props;
    const { isCondoReservation } = condoStatusCheck(reservation);

    if (props.type === 'checkout' && !isCondoReservation) {
        return (
            <div className="text-center ">
                <div>{props.text}</div>
                <div className="mt-2 mb-3">
                    <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                            if (props.continueToCheckout) {
                                props.continueToCheckout(props.reservation, props.type);
                            }
                        }}
                    >
                        {translate('Proceed')}
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        {translate('Cancel')}
                    </button>
                </div>
            </div>
        );
    } else {
        return (
            <div className="text-center">
                <div>{props.text}</div>
                <div className="mt-2 mb-3">
                    <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                            props.confirmActionHandler(props.type);
                        }}
                    >
                        {translate('Yes')}
                    </button>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        {translate('No')}
                    </button>
                </div>
            </div>
        );
    }
}

function getButtons(reservation: Reservation) {
    const currStatus: ReservationStatus = reservation.statusEnum;
    const buttons = [];
    if (currStatus === ReservationStatus.earlyBird) {
        buttons.push(ReservationStatusActions.confirm);
        buttons.push(ReservationStatusActions.checkin);
        buttons.push(ReservationStatusActions.cancel);
    } else if (currStatus === ReservationStatus.confirmed) {
        buttons.push(ReservationStatusActions.checkin);
        buttons.push(ReservationStatusActions.cancel);
    } else if (currStatus === ReservationStatus.canceled) {
        buttons.push(ReservationStatusActions.confirm);
    } else if (currStatus === ReservationStatus.checkedIn) {
        buttons.push(ReservationStatusActions.checkout);
    } else if (currStatus === ReservationStatus.autoBlocked) {
        buttons.push(ReservationStatusActions.confirm);
        buttons.push(ReservationStatusActions.checkin);
        buttons.push(ReservationStatusActions.cancel);
    }
    return buttons;
}

function StatusButtons(props: StatusButtonsProps) {
    const { reservation, history, isPending } = props;
    const ctrl = new ReservationController();

    if (reservation.accommodationPriceListId === null && !reservation.openPrice) {
        if (reservation.statusEnum !== reservationEnums.canceled) {
            return null;
        }
    }
    const { isCondoReservation } = condoStatusCheck(reservation);

    const confirmActionHandler = async (action: ReservationStatusActions, applyCurrentTime?: boolean) => {
        if (props.statusButtonActionHandler) {
            try {
                await props.statusButtonActionHandler(action, applyCurrentTime);
                if (action === 'cancel') {
                        ctrl.save(reservation.groupUuid ? true : false);
                }
            } catch (err) {
                console.warn(err);
            }
            closeModal();
        }
    };

    const onClickHandler = (type: ReservationStatusActions) => {
        switch (type) {
            case ReservationStatusActions.confirm:
                setModalContent(
                    <ConfirmModal
                        type={type}
                        text={translate('Do you want to move this reservation to CONFIRMED state? ')}
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm reservation'),
                    false,
                    'modal-md'
                );

                break;
            case ReservationStatusActions.checkin:
                setModalContent(
                    <CheckInAndOutTimeOptions
                        type={type}
                        text={
                            <div>
                                {translate('Are you sure you want change status to CHECK IN')}? <br />{' '}
                                <small>
                                    {translate('NOTE: Please select time that will be applyed as check in time')}!
                                </small>
                            </div>
                        }
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm checkin'),
                    false,
                    'modal-md'
                );
                break;
            case ReservationStatusActions.cancel: //
                setModalContent(
                    <ConfirmModal
                        type={type}
                        text={translate('Are you sure you want to cancel this reservation?')}
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm cancel reservation'),
                    false,
                    'modal-md'
                );
                break;
            case ReservationStatusActions.checkout:
                if (!isCondoReservation) {
                    if (reservation.groupUuid) {
                        history.push(`/issueGroupInvoice/${reservation.groupUuid}?reservationId=${reservation.id}`);
                    } else {
                        history.push(`/checkoutAndInvoice/${reservation.id}`);
                    }
                } else {
                     setModalContent(
                    <CheckInAndOutTimeOptions
                        type={type}
                        text={
                            <div>
                                {translate('Are you sure you want to close reservation?')} <br />
                                <small>
                                    {translate('NOTE: Please select time that will be applyed as check out time')}!
                                </small>
                            </div>
                        }
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                        translate('Confirm cancel reservation'),
                    false,
                    'modal-md'
                );
                }

                break;
            case ReservationStatusActions.earlyBird:
                //implement if needed
                break;
            default:
                break;
        }
    };

    const buttons = getButtons(reservation);
    return (
        <div className="display-flex">
            {buttons.includes(ReservationStatusActions.confirm) && (
                <button
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.confirm);
                    }}
                    className="button-primary mr-8p"
                >
                    {translate('CONFIRM')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.checkin) && (
                <button
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.checkin);
                    }}
                    className="button-success mr-8p"
                >
                    {translate('CHECK IN')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.cancel) && (
                <button
                    disabled={reservation.genuineInvoiceIssued ? true : false}
                    onClick={() => {
                        if (reservation.providerReservation) {
                            toast('You can not cancel reservation from booking channels!', {
                                position: toast.POSITION.TOP_RIGHT,
                                type: toast.TYPE.ERROR,
                            });
                        } else {
                            onClickHandler(ReservationStatusActions.cancel);
                        }
                    }}
                    className="button-warning mr-8p"
                >
                    {translate('CANCEL')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.checkout) && (
                <button
                    disabled={isPending}
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.checkout);
                    }}
                    className="button-secondary mr-8p"
                >
                    {translate('CHECKOUT')}
                </button>
            )}
        </div>
    );
}

interface CheckInAndOutTimeOptionsProps {
    type: ReservationStatusActions;
    confirmActionHandler: (action: ReservationStatusActions, applyCurrentTime?: boolean) => void;
    continueToCheckout?: (reservation: Reservation, type: ReservationStatusActions) => void;
    reservation: Reservation;
    text?: any;
}

export const CheckInAndOutTimeOptions = (props: CheckInAndOutTimeOptionsProps) => {
    return (
        <div className="px-2 ">
            <div className="text-center">{props.text}</div>
            <hr />
            <div className="flex-center-center">
                <div
                    onClick={async () => {
                        props.confirmActionHandler(props.type, true);
                    }}
                    className="button-primary mr-8p"
                >
                    {translate('Apply current time')}
                </div>
                <div
                    onClick={async () => {
                        props.confirmActionHandler(props.type, false);
                    }}
                    className="button-info mr-8p"
                >
                    {translate('Apply the planned time')}
                </div>

                <div
                    onClick={() => {
                        closeModal();
                    }}
                    className="button-light mr-8p"
                >
                    {translate('Cancel')}
                </div>
            </div>
        </div>
    );
};

export default StatusButtons;
