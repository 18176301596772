import './main.scss';
import 'bootstrap';
import './assets/custom/redesign.scss';

import './assets/fontAwesome/css/font-awesome.min.css';
import './assets/custom/style.css';
import './assets/custom/animate.css';

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import socketClient from './data/sockets';
import baobabTree from './data/state';
import Routes from './router';

import { loadStartupModelData } from './data/actions/startupLoaders';
import * as serviceWorker from './serviceWorker';
import { root } from 'baobab-react/higher-order';
import moment from 'moment/min/moment-with-locales';
import './data/licenceValidityNotifications';
import translate from './data/translations';
import { licenceValidityCheck } from './data/licenceValidityNotifications';
import { initKeycloak } from './keycloak';

window.translate = translate;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://f7e95a7e60894b49b115c7778d1e4595@o189091.ingest.sentry.io/5585492',
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.5,
    });
}

const LoadAppData = async() => {
            try {
                const location = window.location.href;
                if (location.includes('/invoiceView')) {
                    await loadStartupModelData(socketClient, true);
                } else {
                    await loadStartupModelData(socketClient);
                }
                licenceValidityCheck();
            } catch (err) {
                console.warn(err);
                console.log('SHOW ERROR LOADING SCREEN');
            }
};

moment.locale(baobabTree.root.select(['locale']).get());

const RootedApp = root(baobabTree, Routes);
ReactDOM.render(
    <RootedApp />,
    document.getElementById('root'),
    async () => {
        try {
            let keycloakBaobab = baobabTree.root.select('keycloak').get();
            console.log('%cloading app data', 'color:green');
            
            try {
                if(navigator.onLine){
                    await initKeycloak(keycloakBaobab?.refreshToken ? { onLoad: 'login-required' } : {});
                }
            } catch (error) {
                console.log('', error);
            }
            await LoadAppData()
            // If you want your app to work offline and load faster, you can change
            // unregister() to register() below. Note this comes with some pitfalls.
            // Learn more about service workers: http://bit.ly/CRA-PWA
            serviceWorker.unregister();
        } catch (err) {
            console.warn(err);
            console.log('SHOW ERROR LOADING SCREEN');
        }
    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
